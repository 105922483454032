const countryBrands = {
    KR: ["AV", "BB", "CL", "EL", "JM", "DA", "LL", "CM", "AR", "TF"],
    JPN: ["AV", "BB", "EL", "JML", "LL", "LM", "LS", "MC", "TF", "TO"],
}

//品牌的定义
const commonBrandMapping = {
    BB: {
        name: 'bobbibrown',
        display: 'Bobbi Brown',
        privacyDisplay: 'Bobbi Brown',
        header: '/img/BB_Logotype_SingleLine_Black_CMYK.png',
        footer: '/img/BB_Logotype_SingleLine_Black_CMYK.png',
        color: '#ffffff',
    },
    CL: {
        name: 'clinique',
        display: 'Clinique',
        privacyDisplay: 'Clinique',
        header: '/img/CLINIQUE.png',
        footer: '/img/CLINIQUE.png',
        color: '#ffffff',
    },
    EL: {
        name: 'esteelauder',
        display: 'Estee Lauder',
        privacyDisplay: 'Estee Lauder',
        header: '/img/esteelauder_logo.png',
        footer: '/img/esteelauder_logo.png',
        color: '#ffffff',
    },
    MC: {
        name: 'mac',
        display: 'MAC',
        privacyDisplay: 'MAC',
        header: '/img/MAC.png',
        footer: '/img/MAC.png',
        color: '#ffffff',
    },
    AV: {
        name: 'aveda',
        display: 'Aveda',
        privacyDisplay: 'Aveda',
        header: '/img/Aveda.png',
        footer: '/img/Aveda.png',
        color: '#ffffff',
    },
    AR: {
        name: 'labseries',
        display: 'Lab Series',
        privacyDisplay: 'Lab Series',
        header: '/img/ls_logo_header.png',
        footer: '/img/ls_logo_footer.png',
        color: '#ffffff',
    },
    LS: {
        name: 'labseries',
        display: 'Lab Series',
        privacyDisplay: 'Lab Series',
        header: '/img/ls_logo_header.png',
        footer: '/img/ls_logo_footer.png',
        color: '#ffffff',
    },
    CM: {
        name: 'lamer',
        display: 'La Mer',
        privacyDisplay: 'La Mer',
        header: '/img/La_Mer_Logo_Green.png',
        footer: '/img/La_Mer_Logo_Green.png',
        color: '#ffffff',
    },
    LM: {
        name: 'lamer',
        display: 'La Mer',
        privacyDisplay: 'La Mer',
        header: '/img/La_Mer_Logo_Green.png',
        footer: '/img/La_Mer_Logo_Green.png',
        color: '#ffffff',
    },
    JM: {
        name: 'jomalone',
        display: 'Jo Malone',
        privacyDisplay: 'Jo Malone',
        header: '/img/jomalone_logo_header.png',
        footer: '/img/jomalone_logo_footer.png',
        color: '#ffffff',
    },
    JML: {
        name: 'jomalone',
        display: 'Jo Malone London',
        privacyDisplay: 'Jo Malone London',
        header: '/img/jomalone_logo_header.png',
        footer: '/img/jomalone_logo_footer.png',
        color: '#ffffff',
    },
    LL: {
        name: 'lelabo',
        display: 'Le Labo',
        privacyDisplay: 'Le Labo',
        header: '/img/LL.png',
        footer: '/img/LL.png',
        color: '#ffffff',
    },
    TF: {
        name: 'tomford',
        display: 'Tom Ford',
        privacyDisplay: 'ELC',
        header: '/img/TF.png',
        footer: '/img/TF.png',
        color: '#ffffff',
    },
    DA: {
        name: 'darphin',
        display: 'Darphin',
        privacyDisplay: 'Darphin',
        header: '/img/DP.png',
        footer: '/img/DP.png',
        color: '#ffffff',
    },
    TO: {
        name: 'theordinary',
        display: 'The Ordinary',
        privacyDisplay: 'The Ordinary',
        header: '/img/TO.png',
        footer: '/img/TO.png',
        color: '#ffffff',
    },
}

const countryBrandMapping = {
    KR: {
        BB: {
            privacyPolicy: 'https://www.bobbibrown.co.kr/customer-service-corporate',
            conditions: 'https://www.bobbibrown.co.kr/customer-service-corporate#termsofuse',
            marketing: 'https://www.bobbibrown.co.kr/customer-service-corporate#marketingoptin',
        },
        CL: {
            privacyPolicy: 'https://www.cliniquekorea.co.kr/customer-care-privacy-policy',
            conditions: 'https://www.cliniquekorea.co.kr/customer-terms-policy',
            marketing: 'https://www.cliniquekorea.co.kr/marketingoptin',
        },
        EL: {
            privacyPolicy: 'https://www.esteelauder.co.kr/privacy',
            conditions: 'https://www.esteelauder.co.kr/terms-conditions',
            marketing: 'https://www.esteelauder.co.kr/marketingoptin',
        },
        AV: {
            privacyPolicy: 'https://www.avedakorea.com/customer-service-privacy-policy',
            conditions: 'https://www.avedakorea.com/customer-service-terms',
            marketing: 'https://www.avedakorea.com/marketingoptin',
        },
        AR: {
            privacyPolicy: 'https://www.labseries.co.kr/customer-service-privacy-policy',
            conditions: 'https://www.labseries.co.kr/customer-service-terms-conditions',
            marketing: 'https://www.labseries.co.kr/marketingoptin',
        },
        CM: {
            privacyPolicy: 'https://www.lamerkorea.com/customer-service-privacy-policy',
            conditions: 'https://www.lamerkorea.com/customer-service-terms-conditions',
            marketing: 'https://www.lamerkorea.com/marketingoptin',
        },
        JM: {
            privacyPolicy: 'https://www.jomalone.co.kr/privacy-policy',
            conditions: 'https://www.jomalone.co.kr/terms-conditions',
            marketing: 'https://www.jomalone.co.kr/marketingoptin',
        },
        LL: {
            privacyPolicy: 'https://www.lelabofragrances.co.kr/privacy-policy.html',
            conditions: 'https://www.lelabofragrances.co.kr/terms-and-conditions.html',
            marketing: 'https://www.lelabofragrances.co.kr/_marketingoptin',
        },
        TF: {
            privacyPolicy: 'https://www.qa-microsite.digitalcapture.elcompanies.com/KOR/tomford/privacy_final',
            conditions: 'https://www.qa-microsite.digitalcapture.elcompanies.com/KOR/tomford/tc_final',
            marketing: 'https://www.microsite.digitalcapture.elcompanies.com/KOR/tomford/optin',
        },
        DA: {
            privacyPolicy: 'https://www.darphin.co.kr/privacy',
            conditions: 'https://www.darphin.co.kr/terms-conditions',
            marketing: 'https://www.darphin.co.kr/marketingoptin',
        },
    },
    JPN: {
        AV: {
            privacyPolicy: 'https://www.aveda.jp/customer-service-privacy',
            conditions: 'https://www.aveda.jp/customer-service-terms',
        },
        BB: {
            privacyPolicy: 'https://www.bobbibrown.jp/customer-service-corporate#corporate-privacy',
            conditions: 'https://www.bobbibrown.jp/customer-service-corporate#corporate-terms',
        },
        EL: {
            privacyPolicy: 'https://www.esteelauder.jp/customer_service/privacy',
            conditions: 'https://www.esteelauder.jp/customer_service/terms-conditions',
        },
        JML: {
            privacyPolicy: 'https://www.jomalone.jp/privacy-policy',
            conditions: 'https://www.jomalone.jp/terms-conditions',
        },
        LM: {
            privacyPolicy: 'https://www.delamer.jp/customer-service-privacy-policy',
            conditions: 'https://www.delamer.jp/customer-service-terms-conditions',
        },
        LL: {
            privacyPolicy: 'https://www.lelabofragrances.jp/pages/privacy-policy',
            conditions: 'https://www.lelabofragrances.jp/pages/terms-conditions',
        },
        MC: {
            privacyPolicy: 'https://www.maccosmetics.jp/privacy-policy',
            conditions: 'https://www.maccosmetics.jp/terms-conditions',
        },
        TF: {
            privacyPolicy: 'https://www.elcompanies.com/en/privacy/privacy-policy-sdk-pages-holder/japan-all-brands-consumer-privacy-policy-jaapanese',
            conditions: '',
            display: 'Tom Ford',
        },
        LS: {
            privacyPolicy: 'https://www.labseries.jp/customer-service-privacy-policy',
            conditions: 'https://www.labseries.jp/customer-service-terms-conditions',
        },
        TO: {
            privacyPolicy: 'https://theordinary.com/en-jp/privacy-policy.html',
            conditions: 'https://theordinary.com/en-jp/terms.html',
        }
    }
}

function GetBrandConfig(country, brand) {
    if (!(countryBrands[country] && countryBrands[country].find(i => i == brand))) {
        return null;
    }

    var data = {
        ...commonBrandMapping[brand],
        ...countryBrandMapping[country][brand]
    }
    return data
}

export { GetBrandConfig }